import React from 'react'
import { Navbar } from '../components/navbar'
import { Slide } from '../components/slider'
import services from '../data/services'
import { Opensource } from '../components/opensource'
import insights from '../data/insights'

export const Home = () => {
  return (
    <div className='bg-precision w-[100%] h-[100%] flex flex-col'>
        <Navbar/>
        <div className='self-center md:h-[80%] flex items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
            <p className='w-[100%] mt-10 p-2 '> 
              <span className='text-2xl font-bold text-center text-md md:text-2xl'>Scale and Streamline your business by leveraging</span> 
              <br/>
              <span className='text-2xl font-bold md:text-6xl text-emerald-300'>Cloud Technologies & AI</span>
              <br/>
              <br/>
              <span className='font-mono text-xs text-gray-400 md:text-sm'>
                Our expertise is to help Businesses of all sizes to adopt Cloud and Artificial Intelligence solutions,using best practices
              </span>
            </p>
           {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
        </div>
        <div className='text-xs md:text-2xl flex flex-col font-mono items-center text-white md:font-extrabold text-center justify-center md:flex md:flex-col h-[30%] w-[100%]'>
          <h1>Trusted by reputable and heavyweight Organizations</h1>
          {/*<Slide></Slide>*/}
        </div>
        <div className='m-10 md:m-[100px]'>
        <Slide></Slide>
        </div>
        <div className=' text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col h-[70%] w-[80%]'>
          <h1 className='m-4'>Our Services</h1>
          <div className='w-[100%] h-[40%]'>
            <div className='w-[100%]  md:text-m justify-evenly grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3'>
              {
                services.map((s)=><div className='hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105 flex flex-col justify-between items-center rounded-lg border border-white/[0.05] bg-white/[.05] p-4 shadow-md md:h-[350px]'>
                <div className='flex justify-evenly'>
                  {s.icon?s.icon:null}
                  <h5 className='text-lg ml-2'>{s.name}</h5>
                </div>  
                <p className='text-sm'>{s.desc}</p>
                <button className='text-white text-sm bg-precision h-[50px] w-[60%]'>{s.button}</button>
              </div>)
              }
              
            </div>
          </div>
          {/*<Slide></Slide>*/}
        </div>

        <div className='m-10 text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col h-[70%] w-[80%]'>
          <h1 className='text-m'>Maximize value across the Open source Landscape</h1>
          <h4 className='text-sm'>With support from us for your Enterprise needs</h4>
          <div className='m-5 w-[100%] h-[40%]'>
           <Opensource/>
          </div>
          {/*<Slide></Slide>*/}
        </div>

        <div className=' text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col h-[70%] w-[80%]'>
          <h1>Technical Insights</h1>
          <div className='w-[100%] h-[40%]'>
            <div className='w-[100%]  mt-4 grid gap-4 sm:grid-cols-2 xl:grid-cols-3'>
              {
                insights.map((s)=><div className='rounded-lg border border-white/[0.05]
                 bg-white/[.05] p-4 shadow-md md:h-[350px]
                 transform transition-transform duration-200 hover:scale-105
                 hover:cursor-pointer hover:shadow-primary-300
                 hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] 
                 '>
                <div style={{backgroundImage:`url(${s.image}) height:100% width:100% `}} className='w-[100%] h-[100px] bg-cover bg-no-repeat'>
                </div>
                <h6 className='text-sm'>{s.name}</h6>
                <div className='flex items-center w-[50%] justify-between'>
                  <img className='w-6 rounded-full' src={s.author_image}></img>
                  <span className='text-sm'>{s.author}</span>
                </div>
                <span className='text-sm'>{s.date}</span>
                <p className='text-sm'>{s.desc}</p>
              </div>)
              }
              
            </div>
          </div>
          {/*<Slide></Slide>*/}
        </div>

        <div className=' text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col h-[70%] w-[80%]'>
          <h1>Success Stories</h1>
          <div className='w-[100%] h-[40%]'>
            <div className='w-[100%]  mt-4 grid gap-4 sm:grid-cols-2 xl:grid-cols-3'>
              {
                services.map((s)=><div className='rounded-lg border border-white/[0.05] bg-white/[.05] p-4 shadow-md
                transform transition-transform duration-200 hover:scale-105
                hover:cursor-pointer hover:shadow-primary-300
                hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)]
                '>
                <h4>{s.name}</h4>
                <p className='text-sm'>{s.desc}</p>
              </div>)
              }
              
            </div>
          </div>
          {/*<Slide></Slide>*/}
        </div>

        <div className=' text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col h-[70%] w-[80%]'>
          <h1>Customer Feedback</h1>
          <div className='w-[100%] h-[40%]'>
            <div className='text-sm rounded-lg border border-white/[0.05] bg-white/[.05] p-4 shadow-md'>
              <p>
              Nitrovirt assisted Rezolve.ai in migrating to the latest versions of Kubernetes and Postgres, while also taking advantage of additional capabilities. The task of managing live infrastructure that is actively used by customers in production requires special attention, and they successfully navigated this challenge. Furthermore, they researched new technologies relevant to our use case and provided valuable recommendations. Nitrovirt team was dedicated and professional in delivering the desired outcome, and we highly recommend their services.
              </p>
              
            </div>
          </div>
          {/*<Slide></Slide>*/}
        </div>

        <div className=' text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col h-[70%] w-[80%]'>
          <h1>Our Partners</h1>
          <div className='w-[100%] h-[40%]'>
            
          </div>
          {/*<Slide></Slide>*/}
        </div>

        <div className=' flex-col md:flex-row items-left flex md:justify-evenly'>
          {/*block*/}
          <div className='col-span-1 text-white'>
            <h4 className='p-2 text-sm uppercase text-primary-300'>
              AI CONSULTING & SERVICES
            </h4>
            <div className='flex flex-col'>
              <a>AI Cloud</a>
              <a>Generative AI</a>
            </div>
          </div>
          {/*Another block*/}
          <div className='col-span-1 text-white'>
            <h4 className='p-2 text-sm uppercase text-primary-300'>
              PROFESSIONAL SERVICES
            </h4>
            <div className='flex flex-col'>
              <a>Cloud Consulting</a>
              <a>Platform engineering</a>
              <a>Kubernetes Consulting</a>
              <a>Cloud Security</a>
              <a>Location Intelligence</a>
            </div>
          </div>

          {/*Another block*/}
          <div className='col-span-1 text-white'>
            <h4 className='p-2 text-sm uppercase text-primary-300'>
              PROFESSIONAL SERVICES
            </h4>
            <div className='flex flex-col'>
              <a>Cloud Consulting</a>
              <a>Platform engineering</a>
              <a>Kubernetes Consulting</a>
              <a>Cloud Security</a>
              <a>Location Intelligence</a>
            </div>
          </div>
          {/*Another block*/}
          <div className='col-span-1 text-white'>
            <h4 className='p-2 text-sm uppercase text-primary-300'>
              Company
            </h4>
            <div className='flex flex-col'>
              <a>About Us</a>
              <a>Careers</a>
            </div>
          </div>
          {/*Another block*/}
        </div>

    </div>
  )
}


