import { SiKubernetes } from "react-icons/si";
import { FaCloud } from "react-icons/fa";
import { GiPlatform } from "react-icons/gi";
import { GiOffshorePlatform } from "react-icons/gi";
import { GiServerRack } from "react-icons/gi";
import { GrDeploy } from "react-icons/gr";
import { FcDeployment } from "react-icons/fc";
import { MdOutlineSecurity } from "react-icons/md";

const services = [
    {
        name:'DevOps',
        desc:'Elevate your software development with our DevOps expertise. Unlock rapid deployment, enhanced collaboration, and unmatched scalability to deliver exceptional products efficiently.',
        icon:<FcDeployment />,
        button:'Deploy Seemlessly'
    },
    {
        name:'Kubernetes Consulting',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon: <SiKubernetes  color="#326DE6"/>,
        button:'Run k8s at Scale'
    },
    {
        name:'Cloud Consulting',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon:<FaCloud />,
        button:'Maximize Cloud Advantage'
    },
    {
        name:'Platform Engineering',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon:<GiPlatform color="#00E887"/>,
        button:'Efficient Developer Platforms'
    },
    {
        name:'Site Reliability Consulting',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon:<GiServerRack color="#C0A339"/>,
        button:'Reduce Downtime'
    },
    {
        name:'Security',
        desc:'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
        icon:<MdOutlineSecurity />,
        button:'Threat Intelligence'
    },
    
]


export default services